



























































































































































































































































































































































































































































$box-color: linear-gradient(0deg, #02ffff -60%, #001326 100%);
.shop-box {
  .section {
    padding: 50px 0;
    .page-name {
      position: relative;
      font-size: clamp(50px, 5vw, 85px);
      margin: auto;
      text-transform: uppercase;
      color: #fff;
      text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
    }
  }
  .section-1 {
    text-align: center;
    padding: 10px 15px 25px;
    img {
      width: 100%;
      max-width: 740px;
      height: auto;
    }
    @media (max-width: 480px) {
      widows: 100%;
      max-width: unset;
      padding: 15px 0px 15px;
    }
  }
  .section-2 {
    padding: 10px 15px;
    .sub-text {
      color: #6cb40f;
      font-size: clamp(1em, 4vw, 1.5em);
      max-width: 991px;
      line-height: 120%;
      margin: auto;
      text-align: center;
    }
    @media (max-width: 480px) {
      padding: 15px 15px;
      .sub-text {
        font-size: 1.1em;
      }
    }
  }
  .section-3 {
    .box-info-shop {
      color: #f5f6f7;
      background: rgba(33, 34, 34, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 140px;
      width: 100%;
      max-width: 360px;
      padding: 5% 10%;
      border-radius: 10px;
      margin: 15px auto;
      @media (max-width: 480px) {
        min-height: 100px;
      }
      .icons {
        flex: 0 0 70px;
        width: 70px;
        height: 100%;
        img {
          width: 50px;
          height: auto;
        }
      }
      .text {
        flex: 1 1 auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        .text-title {
          color: #6cb40f;
          font-size: 1.2em;
          line-height: 120%;
        }
        .text-content {
          color: #ffffff;
          font-size: 1.4em;
          line-height: 120%;
        }
      }
    }
  }
  .section-4 {
    .inventory {
      position: relative;
      z-index: 10;
    }
    .slick-arrow {
      z-index: 30;
      width: max-content;
      height: max-content;

      &.slick-next {
        right: -2%;
        &:before {
          content: '';
          background: url('~@/assets/images/tour/next.png');
          background-size: 100%100%;
          background-repeat: no-repeat;
          height: 40px;
          width: 25px;
          display: block;
        }
      }
      &.slick-prev {
        left: -2%;
        &:before {
          content: '';
          background: url('~@/assets/images/tour/prev.png');
          background-size: 100%100%;
          background-repeat: no-repeat;
          height: 40px;
          width: 25px;
          display: block;
        }
      }
      @media (min-width: 1366px) {
        &.slick-next {
          right: 0%;
        }
        &.slick-prev {
          left: 0%;
        }
      }
      @media (max-width: 425px) {
        &.slick-next,
        &.slick-prev {
          &:before {
            height: 36px;
            width: 25px;
          }
        }
        &.slick-next {
          right: -25px;
        }
        &.slick-prev {
          left: -25px;
        }
      }
    }
    .box-container {
      background: rgba(33, 34, 34, 1);
      width: 100%;
      max-width: 270px;
      margin: auto;
      border-radius: 15px;
      padding: 10px 0;
      .box-img {
        text-align: center;
        img {
          width: 70%;
          margin: 0 auto;
          filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
        }
      }

      .box-content {
        // background: {
        //   image: url("~@/assets/images/tour/content.png");
        //   size: 100%100%;
        //   position: bottom center;
        //   repeat: no-repeat;
        // }
        margin-top: 5px;
        width: 100%;
        min-height: 300px;
        padding: 15px 10% 0;
        .name {
          font-size: 1em;
          text-align: center;
          margin-bottom: 15px;

          text-transform: uppercase;
          &.diamond {
            color: #ff00ff;
            text-shadow: 1px 0px 9px #ff00ff;
          }
          &.gold {
            color: #f3ff00;
            text-shadow: 1px 0px 9px #f3ff00;
          }
          &.silver {
            color: #6cb40f;
            text-shadow: 1px 0px 9px #6cb40f;
          }
          &.platinum {
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            @media (max-width: 374px) {
              font-size: 1.7em;
            }
          }
        }
        .buy-section {
        }

        button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #f5f6f7;
          padding: 5px 10px;
          border-radius: 25px;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          &.price {
            background-color: #ffa300;
            background-image: conic-gradient(from 1turn, #ffa20074, #ffa300);
          }
          &.buy {
            margin: 0 auto;
            max-width: 150px;
            background-color: #5da000;
            background-image: conic-gradient(from 1turn, #458802, #7bc514);
          }
          .left {
            flex: 0 0 25%;
            color: #ffff;
            font-weight: 600;
          }
          .right {
            flex: 0 0 75%;
            padding-left: 10px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .hbg {
              font-size: 1.4em;
              font-weight: 900;
              line-height: 120%;
            }
            .usd {
              font-size: 1em;
              font-weight: 900;
              margin-left: 10px;
            }
          }
        }

        .detail {
          color: #fff;
          padding: 15px 0;
          text-align: left;
        }
      }
    }
    .input-search {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;
      .form-control {
        max-width: 400px;
        min-width: 300px;
        border: 1px solid rgb(123, 197, 20);
        background-color: rgba(65, 66, 67, 0.452);
        border-left: none;
        border-radius: 15px;
        color: rgb(212, 250, 77);
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
          color: rgb(212, 250, 77);
        }
      }
      .btn-group {
        @media (max-width: 767px) {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        button {
          width: 100%;
          max-width: 100px;
        }
      }
    }
    @media (max-width: 767px) {
      width: 100%;
      padding: 50px 10px;
      max-width: 100%;
    }
  }
}
.confirm-buy,
#reward {
  .text-confirm {
    font-size: 1.2em;
    margin: 10px;
    color: #fff;
    text-align: center;

    p {
      margin-bottom: 5px;
    }
  }
  .modal-content {
    border-radius: 0;
    .modal-header {
      padding: 0 10px;
      color: #000;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 50px;
      width: 100%;
      color: #f5f6f7;
      box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
      background-color: #5da000;
      background-image: conic-gradient(from 1turn, #458802, #7bc514);
      font-weight: bold;
      border: 0;
      .modal-title {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 1.7em;
      }
      .close {
        color: #000;
        opacity: 1;
        font-size: 1.8rem;
        font-weight: 900;
        font-family: fantasy;
        outline: none !important;
      }
    }
    .modal-body {
      margin-top: 0;
      background: #17181b;
    }
    .button-confirm {
      width: 100%;
      max-width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f5f6f7;
      padding: 5px 10px;
      border-radius: 25px;
      box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
      background-color: #5da000;
      background-image: conic-gradient(from 1turn, #458802, #7bc514);
    }
    .button-cancel {
      background: linear-gradient(90deg, #762525 0%, #f92323 100%);
      border-radius: 0;
      width: auto;
      padding: 5px 15px;
      height: 45px;
      color: #fff;
      font-weight: 600;
      text-transform: capitalize;
      min-width: 140px;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 25px auto 10px;
      border: 1px solid;
    }
    input {
      color: #fff;
      font-weight: 600;
      letter-spacing: 1px;
      &::-webkit-input-placeholder {
        color: #fff;
        opacity: 0.6;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 1px;
      }
      height: 40px;
      border: 1px solid #00ffff;
      background: linear-gradient(180deg, #18274a 0%, #001326 100%);
      border-radius: 0;
      margin: 15px auto;
      width: 80%;
      min-width: 200px;
      &:focus,
      &:focus-within {
        color: #fff !important;
        background: #18274a;
      }
    }
  }
  .modal-dialog {
    margin: 20px auto;
    top: 90px;
    @media (max-height: 800px) {
      top: 0;
    }
  }
  .modal-backdrop {
    background-color: rgba($color: #000000, $alpha: 0.7);
  }
  .price {
    font-weight: bold;
    color: #ffa300;
  }
}
#user-inventory {
  .modal-dialog {
    margin-top: 20px;
    max-width: 600px;
  }
  .slick-arrow {
    z-index: 30;
    width: max-content;
    height: max-content;

    &.slick-next {
      right: -2%;
      &:before {
        content: '';
        background: url('~@/assets/images/tour/next.png');
        background-size: 100%100%;
        background-repeat: no-repeat;
        height: 40px;
        width: 25px;
        display: block;
      }
    }
    &.slick-prev {
      left: -2%;
      &:before {
        content: '';
        background: url('~@/assets/images/tour/prev.png');
        background-size: 100%100%;
        background-repeat: no-repeat;
        height: 40px;
        width: 25px;
        display: block;
      }
    }
    @media (min-width: 1366px) {
      &.slick-next {
        right: 0%;
      }
      &.slick-prev {
        left: 0%;
      }
    }
    @media (max-width: 425px) {
      &.slick-next,
      &.slick-prev {
        &:before {
          height: 36px;
          width: 25px;
        }
      }
      &.slick-next {
        right: -25px;
      }
      &.slick-prev {
        left: -25px;
      }
    }
  }
  .box-container {
    background: rgba(33, 34, 34, 1);
    width: 100%;
    max-width: 250px;
    margin: auto;
    border-radius: 15px;
    padding: 10px 0;
    .box-img {
      text-align: center;
      img {
        width: 70%;
        margin: 0 auto;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
      }
    }

    .box-content {
      // background: {
      //   image: url("~@/assets/images/tour/content.png");
      //   size: 100%100%;
      //   position: bottom center;
      //   repeat: no-repeat;
      // }
      margin-top: 5px;
      width: 100%;
      min-height: 200px;
      padding: 15px 10% 0;
      .name {
        font-size: 1em;
        text-align: center;
        margin-bottom: 15px;

        text-transform: uppercase;
        &.diamond {
          color: #ff00ff;
          text-shadow: 1px 0px 9px #ff00ff;
        }
        &.gold {
          color: #f3ff00;
          text-shadow: 1px 0px 9px #f3ff00;
        }
        &.silver {
          color: #98d7e0;
          text-shadow: 1px 0px 9px #98d7e0;
        }
        &.platinum {
          color: #4effff;
          text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
          @media (max-width: 374px) {
            font-size: 1.7em;
          }
        }
      }
      .buy-section {
      }

      button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f5f6f7;
        padding: 5px 10px;
        border-radius: 25px;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        &.price {
          background-color: #ffa300;
          background-image: conic-gradient(from 1turn, #ffa20074, #ffa300);
        }
        &.buy {
          margin: 0 auto;
          max-width: 150px;
          background-color: #5da000;
          background-image: conic-gradient(from 1turn, #458802, #7bc514);
        }
        .left {
          flex: 0 0 25%;
          color: #ffff;
          font-weight: 600;
        }
        .right {
          flex: 0 0 75%;
          padding-left: 10px;
          color: #fff;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .hbg {
            font-size: 1.4em;
            font-weight: 900;
            line-height: 120%;
          }
          .usd {
            font-size: 1em;
            font-weight: 900;
            margin-left: 10px;
          }
        }
      }

      .detail {
        color: #fff;
        padding: 15px 0;
        text-align: center;
      }
    }
  }
}
